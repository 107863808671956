import React, { useState, useEffect, useRef } from 'react';
import DefaultAvatar from '../../assets/defaultAvatar.png';
import Button from '../ui-kit/Button';
import { BsPaperclip } from 'react-icons/bs';
import { SiTon } from "react-icons/si";

function OrderBlock({
  author,
  title,
  desc,
  price,
  responses,
  files,
  hrefButton,
  textButton,
  showButton = [],
  menuItems = [],
  onDelete,
  showMenu = true,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="OrderBlock">
      <div className="header">
        <div className="headerLeft">
          <div className="avatar">
            <img
              src={author.avatar ? `https://api.loonw-space.com:5000${author.avatar}` : DefaultAvatar}
              alt="Avatar"
            />
          </div>
          <div className="name">{author.name}</div>
        </div>

        {showMenu && (
          <div className="headerRight" ref={menuRef}>
            <div className="menuTrigger" onClick={toggleMenu}>
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>

            {menuOpen && (
              <div className="menu">
                {menuItems.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      if (item.action === 'delete') onDelete();
                      else window.location.href = item.href;
                      setMenuOpen(false);
                    }}
                    className="menuItem"
                  >
                    {item.title}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="title">{title}</div>
      <div className="desc">{desc}</div>

      <div className="files">
        {files.map((file, index) => {
          const cleanPath = file.replace(/^\/+/, '');
          const fileUrl = `https://app.loonw-space.com:5000/${cleanPath}`;

          return (
            <a key={index} href={fileUrl} target="_blank" rel="noopener noreferrer">
              <div className="icon">
                <BsPaperclip className="fileIcon" />
              </div>
              <div className="file">{file.split('/').pop()}</div>
            </a>
          );
        })}
      </div>

      <div className="infoOrder">
        <div className="price">
          Допустимая цена: {price} <div className="icon"><SiTon /></div>
        </div>
        <div className="responses">{responses}</div>
      </div>

      {showButton && <Button to={hrefButton} text={textButton} />}
    </div>
  );
}

export default OrderBlock;
