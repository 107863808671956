import React from 'react'
import Button from '../ui-kit/Button'

function NotificationsBlock(props) {
  return (
    <div className='NotificationsBlock'>
        <div className="title">
            {props.title}
        </div>

        <div className="text">
            {props.description}
        </div>

        <div className="date">
            Дата и время: {props.date}
        </div>

        <Button text={props.buttonText} to={props.buttonUrl} />
    </div>
  )
}

export default NotificationsBlock