import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useOrderStore from '../store/orderStore';
import Button from '../components/ui-kit/Button';
import Timer from '../components/Orders/Timer';
import { SiTon } from 'react-icons/si';
import WebApp from '@twa-dev/sdk';
import AcceptWindow from '../components/ui-kit/AcceptWindow';

function OrderDetails() {
  const { orderId } = useParams();
  const {
    fetchOrderDetails,
    proposeCancellation,
    completeOrder,
    submitOrderForReview,
    returnOrderToWork,
    approveOrder,
    orders,
  } = useOrderStore();
  const [order, setOrder] = useState(null);
  const [fileLink, setFileLink] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    fetchOrderDetails(orderId).then(() => {
      const fetchedOrder = orders.find((o) => o._id === orderId);
      setOrder(fetchedOrder);
    });
  }, [fetchOrderDetails, orderId, orders]);

  const userId = WebApp.initDataUnsafe?.user?.id;

  const handleProposeCancellation = () => {
    if (!order || !order._id || !order.executorId) {
      console.error('Не удалось получить orderId или userId');
      return;
    }

    proposeCancellation({ orderId: order._id, userId });
    setIsDialogOpen(false);
  };

  const handleCompleteOrder = async () => {
    if (fileLink) {
      await completeOrder(orderId, fileLink);
      setFileLink('');
    }
  };

  const handleSubmitForReview = (e) => {
    e.preventDefault()
    if (!order) return;
    submitOrderForReview(order._id);
  };

  const handleReturnToWork = (e) => {
    e.preventDefault()
    if (!order) return;
    returnOrderToWork(order._id);
  };

  const handleApproveOrder = (e) => {
    e.preventDefault()
    if (!order) return;
    approveOrder(order._id);
  };

  const formatDate = (date) => {
    if (!date) return 'Заказ в работе';
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(date).toLocaleString('ru-RU', options);
  };

  if (!order) return <div>Загрузка данных...</div>;

  const showCancelButton = order.status === 'В работе';

  return (
    <div className="OrderDetails">
      <div className="OrderDetails__container">
        <div className="titlePage">Детали заказа #{order.orderId}</div>
      </div>

      {order.status === 'В работе' && (
        <Timer startDate={order.startDate} deadline={order.deadline} />
      )}

      <div className="OrderDetails__container">
        <div className="orderDetails__block orderDetails__user">
          <span>Заказчик:</span>
          <div className="userBlock">
            <a href={`/profile/${order.customerId.telegramId}`}>
              {order.customerId.nickname}
            </a>
          </div>
        </div>

        <div className="orderDetails__block orderDetails__user">
          <span>Исполнитель:</span>
          <div className="userBlock">
            <a href={`/profile/${order.executorId.telegramId}`}>
              {order.executorId.nickname}
            </a>
          </div>
        </div>

        <div className="orderDetails__block">
          <span>Название заказа:</span> {order.title}
        </div>

        <div className="orderDetails__block price">
          <span>Стоимость заказа:</span>
          {order.actualPrice}
          <div className="icon">
            <SiTon />
          </div>
        </div>

        <div className="orderDetails__block">
          <span>Старт заказа:</span> {formatDate(order.startDate)}
        </div>

        <div className="orderDetails__block">
          <span>Конец заказа:</span>{' '}
          {formatDate(order.cancellationDate || order.completionDate || order.acceptedDate) || 'Заказ еще в работе'}
        </div>

        <div className={`orderDetails__buttons`}>
          <div className={`button ${!showCancelButton ? 'fullWidth' : ''}`}>
            <Button to={`/chat/${order.chatId}`} text="Открыть чат" />
          </div>

          {showCancelButton && (
            <div className="button">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setIsDialogOpen(true);
                }}
                text={
                  order.cancellationStatus === 'proposed' && order.cancellationProposedBy.telegramId == userId
                    ? "Отменить отмену"
                    : "Отменить заказ"
                }
              />
            </div>
          )}
        </div>

        {order.executorId.telegramId == userId && order.status === 'В работе' ? (
          <div className="passOrder">
            <Button text="Сдать заказ на проверку" onClick={handleSubmitForReview} />
          </div>
        ) : null}

        {order.customerId.telegramId == userId && order.status === 'На проверке' ? (
          <div className="reviewButtons">
            <Button text="Принять заказ" onClick={handleApproveOrder} />
            <Button text="Вернуть на доработку" onClick={handleReturnToWork} />

            <div className="text">
              Исполнитель должен был предоставить результаты работы в чате.
            </div>
          </div>
        ) : null}

        {order.executorId.telegramId == userId && order.status === 'На проверке' ? (
          <div className="reviewInfo">
            <p>Проверьте, предоставили ли вы результаты проделанной работы в чате.</p>
          </div>
        ) : null}

        {order.cancellationStatus === 'proposed' && order.customMessages ? (
          <div className="cancellationMessage">
            {order.cancellationProposedBy.telegramId == userId ? (
              <p>{order.customMessages.requestInitiator}</p>
            ) : (
              <p>{order.customMessages.requestRecipient}</p>
            )}
          </div>
        ) : null}
      </div>

      <AcceptWindow
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onApprove={handleProposeCancellation}
      />
    </div>
  );
}

export default OrderDetails;
