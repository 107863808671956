import React, { useEffect } from 'react';
import WebApp from '@twa-dev/sdk';
import useOrderStore from '../store/orderStore';
import OrderBlock from '../components/Orders/OrderBlock';

const durationMapping = {
  '1d': '1 день',
  '2d': '2 дня',
  '3d': '3 дня',
  '1w': '1 неделя',
  '2w': '2 недели',
};

function MyResponses() {
  const { orders, loading, fetchUserResponses } = useOrderStore();
  const telegramId = WebApp.initDataUnsafe?.user?.id;

  useEffect(() => {
    if (telegramId) {
      fetchUserResponses(telegramId);
    }
  }, [telegramId, fetchUserResponses]);

  return (
    <div className="MyResponses">
      <div className="MyResponses__container">
        <div className="titlePage">Мои отклики:</div>

        {loading ? (
          <div>Загрузка...</div>
        ) : orders.length === 0 ? (
          <div>У вас пока нет откликов.</div>
        ) : (
          <div className="responsesList">
            {orders.map((order) => (
              <OrderBlock
                key={order.orderId}
                author={{
                  name: order.customer.nickname,
                  avatar: order.customer.avatar,
                }}
                title={order.title}
                desc={order.description}
                price={order.response.price}
                responses={`Срок: ${durationMapping[order.response.duration] || order.response.duration}`}
                files={[]}
                showButton
                textButton="Смотреть мой отклик"
                hrefButton={`/chat/${order.chatId}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default MyResponses;
