import React, { useEffect, useState } from 'react';
import { BsHouse, BsChat, BsCart, BsBell, BsPerson } from "react-icons/bs";
import { Link } from 'react-router-dom';
import useUserStore from '../store/userStore';

function BottomNavigation() {
  const { user, fetchUserInfo } = useUserStore();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  useEffect(() => {
    if (user?.notifications) {
      setNotificationCount(user.notifications.length);
    }
  }, [user]);

  const navItems = [
    { href: '/', label: 'Главная', icon: <BsHouse /> },
    { href: '/chats', label: 'Чаты', icon: <BsChat /> },
    { href: '/orders', label: 'Заказы', icon: <BsCart /> },
    { href: '/notifications', label: 'Уведомл.', icon: <BsBell />, count: notificationCount },
    { href: '/menu', label: 'Меню', icon: <BsPerson /> },
  ];

  const currentPath = window.location.pathname;

  return (
    <div className="BottomNavigation">
      <div className="nav__container">
        {navItems.map((item, index) => (
          <Link
            key={index}
            to={item.href}
            className={`item ${currentPath === item.href ? 'active' : ''}`}
          >
            <div className="iconContainer">
              <div className="icon">
                {item.icon}
              </div>
              {item.count > 0 && (
                <div className="badge">
                  {item.count > 99 ? '99+' : item.count}
                </div>
              )}
            </div>

            <div className="text">
              {item.label}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default BottomNavigation;
