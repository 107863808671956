import React, { useEffect } from 'react';
import BalanceHeader from '../components/Balance/BalanceHeader';
import { useNavigate } from 'react-router-dom';
import WebApp from '@twa-dev/sdk';
import useWalletStore from '../store/walletStore';

function Balance() {
  const navigate = useNavigate();
  const { transactions, isLoading, fetchTransactions } = useWalletStore();

  useEffect(() => {
    const telegramId = WebApp.initDataUnsafe?.user?.id;
    if (telegramId) {
      fetchTransactions(telegramId);
    }
  }, [fetchTransactions]);

  useEffect(() => {
    const backButton = WebApp.BackButton;

    backButton.show();
    backButton.onClick(() => {
      console.log('[TWA BackButton] Возврат назад');
      navigate('/menu');
    });

    return () => {
      backButton.hide();
      backButton.offClick();
    };
  }, [navigate]);

  return (
    <div className='Balance'>
      <div className="Balance__container">
        <div className="titlePage">Баланс</div>
        <BalanceHeader />
        <div className="transactions">
          {isLoading ? (
            <div>Загрузка...</div>
          ) : transactions.length === 0 ? (
            <div className="text">История транзакций пуста</div>
          ) : (
            <div className="transactions__list">
              {transactions
                .slice()
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((transaction, index) => (
                  <div key={index} className="transaction__item">
                    <div>{transaction.type} <span>{transaction.amount} {transaction.currency}</span></div>
                    <div>{new Date(transaction.date).toLocaleDateString()}</div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Balance;
