import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import BottomNavigation from './components/BottomNavigation';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from './pages/Menu';
import AddOrder from './pages/AddOrder';
import Orders from './pages/Orders';
import Referrals from './pages/Referrals';
import EditProfile from './pages/EditProfile';
import Profile from './pages/Profile';
import Category from './pages/Category';
import AddAdvertising from './pages/AddAdvertising';
import Advertising from './pages/Advertising';
import MyAdvertising from './pages/MyAdvertising';
import Balance from './pages/Balance';
import AddResponse from './pages/AddResponse';
import Chat from './pages/Chat';
import Chats from './pages/Chats';
import Notifications from './pages/Notifications';
import Tasks from './pages/Tasks';
import WebApp from '@twa-dev/sdk';
import Cookies from 'js-cookie';
import Portfolio from './pages/Portfolio';
import AddJobInPortfolio from './pages/AddJobInPortfolio';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import MyResponses from './pages/MyResponses';
import OrderResponses from './pages/OrderResponses';
import OrderDetails from './pages/OrderDetails';

function Loader() {
    return (
        <div className="loader">
            <div className="spinner"></div>
        </div>
    );
}

function MainContent() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, [location]);

    const hideBottomNav = location.pathname.includes('/chat/');

    useEffect(() => {
        const telegramId = WebApp.initDataUnsafe?.start_param;

        if (telegramId && telegramId !== "command" && Cookies.get('redirected') !== 'true') {
            Cookies.set('redirected', 'false', { expires: 1, path: '/' });

            if (!location.pathname.includes(`/profile/${telegramId}`)) {
                WebApp.initDataUnsafe.start_param = null;
                console.log('Redirecting to /profile/' + telegramId);

                Cookies.set('redirected', 'true', { expires: 1, path: '/' });

                navigate(`/profile/${telegramId}`);
            }
        } else {
            console.warn('Неверный telegramId или редирект уже выполнен:', telegramId);
        }
    }, [location.pathname, navigate]);

    return (
        <>
            {loading && <Loader />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/category/:id" element={<Category />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/addOrder" element={<AddOrder />} />
                <Route path="/addAdvertising" element={<AddAdvertising />} />
                <Route path="/myAdvertising" element={<MyAdvertising />} />
                <Route path="/advertising/:categoryId/:subCategoryId" element={<Advertising />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/referrals" element={<Referrals />} />
                <Route path="/editProfile" element={<EditProfile />} />
                <Route path="/profile/:telegramId" element={<Profile />} />
                <Route path="/balance" element={<Balance />} />
                <Route path="/addResponse/:orderId" element={<AddResponse />} />
                <Route path="/chats" element={<Chats />} />
                <Route path="/chat/:chatId" element={<Chat />} />
                <Route path="/notifications" element={<Notifications />} />
                <Route path="/tasks" element={<Tasks />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/addJobPortfolio" element={<AddJobInPortfolio />} />
                <Route path="/myResponses" element={<MyResponses />} />
                <Route path="/order/responses/:orderId" element={<OrderResponses />} />
                <Route path="/order/details/:orderId" element={<OrderDetails />} />
            </Routes>

            {!hideBottomNav && <BottomNavigation />}
        </>
    );
}

function App() {
    useEffect(() => {
        if (!window.Telegram?.WebApp) {
            document.body.innerHTML = `
                <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100vh; text-align: center;">
                    <h1>Доступ запрещен</h1>
                    <p>Пожалуйста, откройте приложение через Telegram Mini Apps.</p>
                </div>
            `;
        }
    }, []);

    return (
        <TonConnectUIProvider manifestUrl="https://loonw-space.com/tonconnect-manifest.json">
            <BrowserRouter>
                <MainContent />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </BrowserRouter>
        </TonConnectUIProvider>
    );
}

export default React.memo(App);
