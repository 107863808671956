import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import WebApp from '@twa-dev/sdk';

const useChatStore = create(
  devtools((set, get) => ({
    chats: [],
    currentChat: null,
    messages: [],

    fetchChats: async () => {
      const telegramId = WebApp.initDataUnsafe?.user?.id;
      if (!telegramId) {
        console.error('Не удалось получить telegramId из WebApp.');
        return;
      }

      try {
        const response = await api.get(`/chats/${telegramId}`);
        set({ chats: response.data });
      } catch (error) {
        console.error('[fetchChats] Ошибка при загрузке чатов:', error);
      }
    },

    fetchChatById: async (chatId) => {
      try {
        const response = await api.get(`/chats/chat/${chatId}`);
        set({
          currentChat: response.data,
          messages: response.data.messages,
        });
      } catch (error) {
        console.error('[fetchChatById] Ошибка при загрузке чата:', error);
      }
    },

    sendMessage: async (chatId, text) => {
      const telegramId = WebApp.initDataUnsafe?.user?.id;
      if (!telegramId) {
        console.error('Не удалось получить telegramId из WebApp.');
        return;
      }

      const newMessage = {
        senderTelegramId: telegramId,
        text,
        createdAt: new Date().toISOString(),
      };

      try {
        const response = await api.post(`/chats/chat/${chatId}/messages`, newMessage);

        set((state) => ({
          messages: [...state.messages, response.data],
        }));
      } catch (error) {
        console.error('[sendMessage] Ошибка при отправке сообщения:', error);
      }
    },

    sendFileMessage: async (chatId, text, file) => {
      const telegramId = WebApp.initDataUnsafe?.user?.id;
      if (!telegramId) {
        console.error('Не удалось получить telegramId из WebApp.');
        return;
      }
    
      const formData = new FormData();
      formData.append('senderTelegramId', telegramId);
      if (text) formData.append('text', text);
      if (file) formData.append('file', file);
    
      try {
        const response = await api.post(`/chats/chat/${chatId}/messages-with-file`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
    
        set((state) => ({
          messages: [...state.messages, response.data],
        }));
      } catch (error) {
        console.error('[sendFileMessage] Ошибка при отправке файла:', error);
      }
    },    

    checkNewMessages: async () => {
      const { currentChat, messages } = get();

      if (!currentChat || !currentChat.chatId) {
        console.error('[checkNewMessages] currentChat или его chatId не определены:', currentChat);
        return;
      }

      const lastMessageDate = messages.length > 0
        ? messages[messages.length - 1].createdAt
        : new Date(0).toISOString();

      try {
        const response = await api.get(`/chats/chat/${currentChat.chatId}/new-messages`, {
          params: { lastMessageDate },
        });

        const newMessages = response.data;

        if (newMessages.length > 0) {
          set((state) => ({
            messages: [...state.messages, ...newMessages],
          }));
        }
      } catch (error) {
        console.error('[checkNewMessages] Ошибка при проверке новых сообщений:', error.message);
      }
    },
  }))
);

export default useChatStore;
