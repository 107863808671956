import React, { useEffect } from 'react';
import EmptyData from '../components/EmptyData';
import NotificationsBlock from '../components/Notifications/NotificationsBlock';
import useUserStore from '../store/userStore';

function Notifications() {
  const { user, fetchUserInfo } = useUserStore();

  useEffect(() => {
    fetchUserInfo(); 
  }, [fetchUserInfo]);

  const notifications = user?.notifications || [];

  const sortedNotifications = [...notifications].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div className="Notifications">
      <div className="Notifications__container">
        <div className="titlePage">Уведомления:</div>

        {sortedNotifications.length > 0 ? (
          sortedNotifications.map((notification, index) => (
            <NotificationsBlock
              key={index}
              title={notification.title}
              description={notification.description}
              date={new Date(notification.date).toLocaleString('ru-RU', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
              buttonText={notification.buttonText}
              buttonUrl={notification.buttonUrl}
            />
          ))
        ) : (
          <EmptyData text="Уведомления отсутствуют" />
        )}
      </div>
    </div>
  );
}

export default Notifications;
