import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useOrderStore from '../store/orderStore';
import ResponsesBlock from '../components/Responses/ResponsesBlock';
import AcceptWindow from '../components/ui-kit/AcceptWindow';

function OrderResponses() {
  const { orderId } = useParams();
  const { responses = [], fetchResponsesByOrderId, fetchOrderDetails, assignOrder, orders } = useOrderStore();
  const [orderTitle, setOrderTitle] = useState('');
  const [selectedExecutor, setSelectedExecutor] = useState(null);
  const [isAcceptOpen, setIsAcceptOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Order ID from useParams:', orderId);

    if (orderId) {
      fetchResponsesByOrderId(orderId);

      fetchOrderDetails(orderId).then(() => {
        const order = orders.find((order) => order._id === orderId);
        if (order) {
          setOrderTitle(order.title);
        } else {
          console.error('Заказ не найден в orders:', orders, 'Order ID:', orderId);
        }
      });
    } else {
      console.error('Ошибка: orderId не определен');
    }
  }, [orderId, fetchResponsesByOrderId, fetchOrderDetails, orders]);

  const handleConfirmAssign = async () => {
    if (selectedExecutor) {
      await assignOrder(orderId, selectedExecutor);
      setIsAcceptOpen(false);

      navigate('/orders');
    }
  };

  return (
    <div className="OrderResponses">
      <div className="OrderResponses__container">
        <div className="titlePage">
          Отклики на проект: {orderTitle || 'Загрузка...'}
        </div>

        {responses.length === 0 ? (
          <div>Нет откликов на этот проект.</div>
        ) : (
          responses.map((response) => (
            <ResponsesBlock
              key={response._id}
              user={{
                name: response.userId?.nickname || 'Неизвестный пользователь',
                avatar: response.userId?.avatar || null,
                telegramId: response.userId?.telegramId,
              }}
              price={response.price}
              duration={response.duration}
              description={response.description}
              chatId={response.chatId}
              orderId={orderId}
              onSelectExecutor={() => {
                setSelectedExecutor(response.userId?.telegramId);
                setIsAcceptOpen(true);
              }}
            />
          ))
        )}

        <AcceptWindow
          isOpen={isAcceptOpen}
          onClose={() => setIsAcceptOpen(false)}
          onApprove={handleConfirmAssign}
        />
      </div>
    </div>
  );
}

export default OrderResponses;
