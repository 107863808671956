import React, { useEffect, useState } from 'react';
import OrderBlock from '../components/Orders/OrderBlock';
import EmptyData from '../components/EmptyData';
import useOrderStore from '../store/orderStore';
import useUserStore from '../store/userStore';

function Orders() {
  const { orders, fetchUserOrders, deleteOrder } = useOrderStore();
  const { user, fetchUserInfo } = useUserStore();
  const [activeTab, setActiveTab] = useState('На бирже');

  useEffect(() => {
    fetchUserInfo();
    fetchUserOrders();
  }, [fetchUserInfo, fetchUserOrders]);

  const getOrderCountByStatus = (status) => {
    return orders.filter((order) => order.status === status).length;
  };

  const tabOptions = [
    { label: 'На бирже', status: 'На бирже' },
    { label: 'В работе', status: 'В работе' },
    { label: 'На проверке', status: 'На проверке' },
    { label: 'Завершено', status: 'Завершено' },
    { label: 'Отменено', status: 'Отменено' },
  ];

  return (
    <div className="Orders">
      <div className="Orders__container">
        <div className="tab__grids">
          {tabOptions
            .filter((tab) => !(tab.status === 'На бирже' && user?.role === 'Исполнитель'))
            .map((tab) => (
              <div
                key={tab.status}
                className={`tab__block ${activeTab === tab.status ? 'active' : ''}`}
                onClick={() => setActiveTab(tab.status)}
              >
                {tab.label} ({getOrderCountByStatus(tab.status)})
              </div>
            ))}
        </div>

        {getOrderCountByStatus(activeTab) === 0 ? (
          <EmptyData message="Заказы отсутствуют." />
        ) : (
          orders
          .filter((order) => order.status === activeTab)
          .map((order) => (
            <OrderBlock
              key={order._id}
              author={{
                name: order.customerId.nickname,
                avatar: order.customerId.avatar,
              }}
              title={order.title}
              desc={order.description}
              price={order.acceptablePrice}
              responses={`Откликов: ${order.responses.length}`}
              files={order.files}
              showButton={true}
              textButton={
                activeTab === 'На бирже' ? 'Смотреть отклики' : 'Подробнее'
              }
              hrefButton={
                activeTab === 'На бирже'
                  ? `/order/responses/${order._id}`
                  : `/order/details/${order._id}`
              }
              menuItems={[
                { title: 'Удалить', action: 'delete' },
              ]}
              onDelete={() => deleteOrder(order._id)}
              showMenu={order.status === 'На бирже'} 
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Orders;
