import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import { showNotification } from '../ToastNotification';
import WebApp from '@twa-dev/sdk';

const useUserStore = create(
  devtools((set) => ({
    user: null,
    loading: false,
    otherUser: null,

    fetchUserInfo: async () => {
      set((state) => ({ ...state, loading: true }));
      try {
        const telegramId = WebApp.initDataUnsafe?.user?.id;
        if (!telegramId) {
          console.warn('Telegram ID отсутствует. Запрос не выполнен.');
          set({ loading: false });
          return;
        }

        const response = await api.get(`/user/${telegramId}`);
        
        set((state) => {
          if (JSON.stringify(state.user) !== JSON.stringify(response.data)) {
            return { user: response.data, loading: false };
          }
          return { loading: false };
        });
      } catch (error) {
        console.error('Ошибка при получении информации о пользователе:', error);
        
        if (error.response && error.response.status === 404) {
          window.location.href = "https://t.me/devinprod_ls_bot?start=choose_role";
          window.close();
        }
          
        set({ loading: false });
      }
    },

    fetchOtherUserInfo: async (telegramId) => {
      set({ loading: true });
      try {
        if (!telegramId) {
          throw new Error('Не передан telegramId.');
        }

        const response = await api.get(`/user/${telegramId}`);
        set({ otherUser: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при получении информации о другом пользователе:', error);
        showNotification('Ошибка при загрузке данных другого пользователя', 'error');
        set({ loading: false });
      }
    },

    updateUserInfo: async (updates, avatarFile) => {
      set({ loading: true });
      try {
        const telegramId = WebApp.initDataUnsafe?.user.id;
        if (!telegramId) throw new Error('Не удалось получить telegramId пользователя.');

        const formData = new FormData();
        Object.entries(updates).forEach(([key, value]) => formData.append(key, value));

        if (avatarFile) {
          formData.append('avatar', avatarFile);
        }

        const response = await api.put(`/user/${telegramId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        showNotification('Профиль успешно обновлен!', 'success');
        set({ user: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при обновлении информации о пользователе:', error);
        set({ loading: false });
      }
    },

    fetchUserById: async (userId) => {
      set({ loading: true });
      try {
          const response = await api.get(`/user/id/${userId}`);
          set({ otherUser: response.data, loading: false });
      } catch (error) {
          console.error('Ошибка при получении информации о пользователе по ID:', error);
          showNotification('Ошибка при загрузке данных пользователя', 'error');
          set({ loading: false });
      }
    },  

    setOtherUser: (userData) => set({ otherUser: userData }),
  }))
);

export default useUserStore;
