import React from 'react';
import DefaultAvatar from '../../assets/defaultAvatar.png';
import Button from '../ui-kit/Button';
import { SiTon } from "react-icons/si";
import useOrderStore from '../../store/orderStore';

const durationMapping = {
  '1d': '1 день',
  '2d': '2 дня',
  '3d': '3 дня',
  '1w': '1 неделя',
  '2w': '2 недели',
};

function ResponsesBlock({ user, price, duration, description, chatId, orderId }) {
  const { assignOrder } = useOrderStore();

  const handleAssign = async (e) => {
    e.preventDefault();
    console.log('Assigning executor:', { orderId, executorId: user.telegramId }); // Логируем данные
  
    if (!orderId || !user.telegramId) {
      console.error('Ошибка: отсутствуют необходимые данные:', { orderId, executorId: user.telegramId });
      return;
    }
  
    try {
      await assignOrder(orderId, user.telegramId);
    } catch (error) {
      console.error('Ошибка при назначении исполнителя:', error.response?.data || error.message);
    }
  };
  

  return (
    <div className="ResponsesBlock">
      <div className="header">
        <div className="headerLeft">
          <div className="avatar">
            <img
              src={user.avatar ? `https://api.loonw-space.com:5000${user.avatar}` : DefaultAvatar}
              alt="Avatar"
            />
          </div>
          <div className="name">{user.name}</div>
        </div>
      </div>

      <div className="content">
        <div className="info">
          <div className="infoBlock price">
            <span>Предлагаемая цена:</span> {price} 
            <div className="icon"><SiTon /></div>
          </div>
          <div className="infoBlock">
            <span>Предлагаемый срок:</span> {durationMapping[duration] || duration}
          </div>
        </div>
        <div className="infoBlock">
          <span>Сообщение:</span> {description}
        </div>
      </div>

      <div className="actions">
        <Button
          text="Выбрать как исполнителя"
          onClick={handleAssign}
        />
        <div className="inlineButton">
          <Button 
            text="Открыть чат"
            to={`/chat/${chatId}`}
          />
        </div>
      </div>
    </div>
  );
}

export default ResponsesBlock;
