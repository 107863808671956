import React, { useEffect, useState } from 'react';
import { SiTon } from "react-icons/si";
import { AiFillDollarCircle } from "react-icons/ai";
import { BsCommand } from "react-icons/bs";
import BalanceCurrency from './BalanceCurrency';
import Button from '../ui-kit/Button';
import Input from '../ui-kit/Input';
import useUserStore from '../../store/userStore';
import useWalletStore from '../../store/walletStore';
import { TonConnectUIProvider, TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import WebApp from '@twa-dev/sdk';
import Logo from '../../assets/Logo_Orig.png';

function BalanceHeader() {
    const { user, fetchUserInfo, loading } = useUserStore();
    const {
        walletAddress,
        saveWalletAddress,
        withdrawFunds,
        disconnectWallet,
        updateBalance,
    } = useWalletStore();

    const tonAddress = useTonAddress();
    const [tonConnectUI] = useTonConnectUI();
    const telegramId = WebApp.initDataUnsafe?.user?.id;

    const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
    const [amount, setAmount] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [currency, setCurrency] = useState('TON'); 
    const COMMISSION_RATE = 0.05;

    useEffect(() => {
        if (!user) {
            fetchUserInfo();
        }
    }, [user, fetchUserInfo]);

    useEffect(() => {
        if (tonAddress) {
            saveWalletAddress(telegramId, tonAddress);
        }
    }, [tonAddress, telegramId, saveWalletAddress]);

    const handleTopUp = async (e) => {
        e.preventDefault();

        const MAIN_WALLET_ADDRESS = "UQBhJ2kA25O4YD0h6N1mmTzLoeyJhQ2-rd5-fwiVaZe8ImmT";
        const COMMISSION_WALLET_ADDRESS = "UQAESOsizfuoa9d_3kCEcab7cd1olojW4-ARJ5CcZl-wAUSr";

        const amountValue = parseFloat(amount);
        if (isNaN(amountValue) || amountValue <= 0) {
            return;
        }

        const amountInNano = Math.floor(amountValue * 1e9);
        const commission = Math.floor(amountInNano * COMMISSION_RATE);

        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 300,
            messages: [
                {
                    address: MAIN_WALLET_ADDRESS,
                    amount: amountInNano.toString(),
                    payload: null,
                },
                {
                    address: COMMISSION_WALLET_ADDRESS,
                    amount: commission.toString(),
                    payload: null,
                },
            ],
        };

        try {
            await tonConnectUI.sendTransaction(transaction);
            await updateBalance(telegramId, amountValue, currency);
            setIsTopUpModalOpen(false);
            fetchUserInfo();
        } catch (error) {
            console.error('[handleTopUp] Transaction error:', error.message);
        }
    };

    const handleWithdrawRequest = async (e) => {
        e.preventDefault()
        const amount = parseFloat(withdrawAmount);
        if (!amount || amount <= 0) {
            return;
        }

        try {
            await withdrawFunds(telegramId, amount);
            setWithdrawAmount('');
            setIsWithdrawModalOpen(false);
        } catch (error) {
            console.error('[handleWithdrawRequest] Ошибка:', error);
        }
    };

    const handleDisconnect = async (e) => {
        e.preventDefault();
        try {
            await tonConnectUI.disconnect();
            await disconnectWallet(telegramId);
        } catch (error) {
            console.error('[handleDisconnect] Ошибка:', error.message);
        }
    };

    if (loading) {
        return <div>Загрузка...</div>;
    }

    if (!user) {
        return <div>Ошибка загрузки пользователя</div>;
    }

    const isPerformer = user.role === 'Исполнитель';

    return (
        <TonConnectUIProvider manifestUrl="https://loonw-space.com/tonconnect-manifest.json">
            <div className="BalanceHeader">
                <BalanceCurrency
                    icon={<SiTon />}
                    color={'#3478F6'}
                    paddingTop={10.5}
                    currentBalance={user.balance}
                    currentCurency={'TON'}
                />
                <BalanceCurrency
                    icon={<AiFillDollarCircle />}
                    color={'#5FBF86'}
                    paddingTop={10.5}
                    currentBalance={user.balanceUSDT}
                    currentCurency={'USDT'}
                />
                <BalanceCurrency
                    icon={<BsCommand />}
                    color={'#6328f6'}
                    paddingTop={10.5}
                    currentBalance={user.balanceLSpace}
                    currentCurency={'$LS'}
                />

                {!tonAddress ? (
                    <TonConnectButton />
                ) : (
                    <div>
                        <p>Кошелек подключен: {`${tonAddress.slice(0, 4)}...${tonAddress.slice(-4)}`}</p>
                        {isPerformer ? (
                            <>
                                <Button type="button" text="Вывод средств" onClick={(e) => {
                                    e.preventDefault() 
                                    setIsWithdrawModalOpen(true)
                                }} />
                                <div className="marginTopWallet">
                                    <Button type="button" text="Отключить кошелек" onClick={handleDisconnect} />
                                </div>
                            </>
                        ) : (
                            <>
                                <Button type="button" text="Пополнить" onClick={(e) => {
                                    e.preventDefault()
                                    setIsTopUpModalOpen(true)
                                }} />
                                <div className="marginTopWallet">
                                    <Button type="button" text="Отключить кошелек" onClick={handleDisconnect} />
                                </div>
                            </>
                        )}
                    </div>
                )}

                {isTopUpModalOpen && (
                    <div className="modal-wrapper">
                        <div className="modal-content">
                            <div className="logo">
                                <img src={Logo} alt="Logo" />
                            </div>
                            <div className="inputWrapp">
                                <Input
                                    type="text"
                                    text="Сумма (TON)"
                                    value={amount}
                                    onChange={(value) => setAmount(value.replace(',', '.'))}
                                />
                            </div>
                            {amount > 0 && (
                                <div className="commContainer">
                                    <div className="left">Итого с комиссией:</div>
                                    <div className="right">{(amount * (1 + COMMISSION_RATE)).toFixed(2)} TON</div>
                                </div>
                            )}
                            <Button type="button" text="Пополнить" onClick={handleTopUp} />
                            <div className="marginTopWalletButton">
                                <a onClick={() => setIsTopUpModalOpen(false)}>Отменить</a>
                            </div>
                        </div>
                    </div>
                )}

                {isWithdrawModalOpen && (
                    <div className="modal-wrapper">
                        <div className="modal-content">
                            <div className="logo">
                                <img src={Logo} alt="Logo" />
                            </div>
                            <div className="inputWrapp">
                                <Input
                                    type="text"
                                    text="Сумма для вывода (TON)"
                                    value={withdrawAmount}
                                    onChange={(value) => setWithdrawAmount(value.replace(',', '.'))}
                                />
                            </div>
                            {withdrawAmount > 0 && (
                                <div className="commContainer">
                                    <div className="left">Итого к выводу:</div>
                                    <div className="right">{(withdrawAmount * (1 - COMMISSION_RATE)).toFixed(2)} TON</div>
                                </div>
                            )}
                            <Button type="button" text="Создать заявку" onClick={handleWithdrawRequest} />
                            <div className="marginTopWalletButton">
                                <a onClick={() => setIsWithdrawModalOpen(false)}>Отменить</a>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </TonConnectUIProvider>
    );
}

export default BalanceHeader;
