import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import { showNotification } from '../ToastNotification';
import WebApp from '@twa-dev/sdk'

const useOrderStore = create(
  devtools((set, get) => ({
    orders: [],
    allOrders: [],
    loading: false,

    fetchOrderDetails: async (orderId) => {
      set({ loading: true });
      try {
        const response = await api.get(`/orders/${orderId}`);
        set((state) => ({
          orders: [...state.orders.filter((order) => order._id !== orderId), response.data],
          loading: false,
        }));
      } catch (error) {
        console.error('Ошибка при загрузке деталей заказа:', error);
        set({ loading: false });
      }
    },

    fetchAllOrders: async () => {
      set({ loading: true });
      try {
        const response = await api.get('/orders/all');
        set({ allOrders: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при загрузке всех заказов:', error);
        set({ loading: false });
      }
    },

    addResponseToOrder: async (data) => {
      set({ loading: true });
      try {
        const response = await api.post('/orders/response', data);
        showNotification('Отклик успешно отправлен!', 'success');
        set((state) => ({
          orders: state.orders.map((order) =>
            order._id === data.orderId
              ? { ...order, responses: [...order.responses, response.data.response] }
              : order
          ),
          loading: false,
        }));
      } catch (error) {
        console.error('Ошибка при добавлении отклика:', error);
        showNotification('Ошибка при добавлении отклика', 'error');
        set({ loading: false });
      }
    },

    fetchUserOrders: async () => {
      set({ loading: true });
      try {
        const telegramId = WebApp.initDataUnsafe?.user.id;
        if (!telegramId) throw new Error('Не удалось получить telegramId пользователя.');

        const response = await api.get(`/orders?telegramId=${telegramId}`);
        set({ orders: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при получении заказов:', error);
        showNotification('Ошибка при загрузке заказов', 'error');
        set({ loading: false });
      }
    },

    createOrder: async (orderData, files) => {
      set({ loading: true });
      try {
        const telegramId = WebApp.initDataUnsafe?.user.id;
        if (!telegramId) throw new Error('Не удалось получить telegramId пользователя.');

        const formData = new FormData();
        formData.append('title', orderData.title);
        formData.append('description', orderData.description);
        formData.append('acceptablePrice', orderData.acceptablePrice);
        formData.append('paymentMethod', orderData.paymentMethod);
        formData.append('telegramId', telegramId);
        formData.append('categoryId', orderData.categoryId);
        formData.append('subcategoryId', orderData.subcategoryId);

        files.forEach((file) => {
          formData.append('files', file.file);
        });

        const response = await api.post('/orders', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        set((state) => ({ orders: [...state.orders, response.data], loading: false }));
        showNotification('Заказ успешно создан!', 'success');
      } catch (error) {
        console.error('Ошибка при создании заказа:', error);
        showNotification('Ошибка при создании заказа', 'error');
        set({ loading: false });
      }
    },

    deleteOrder: async (orderId) => {
      set({ loading: true });
      try {
        await api.delete(`/orders/${orderId}`);
        set((state) => ({
          orders: state.orders.filter((order) => order._id !== orderId),
          loading: false,
        }));
        showNotification('Заказ успешно удалён!', 'success');
      } catch (error) {
        console.error('Ошибка при удалении заказа:', error);
        showNotification('Ошибка при удалении заказа', 'error');
        set({ loading: false });
      }
    },
    
    fetchUserResponses: async (telegramId) => {
      set({ loading: true });
      try {
        const response = await api.get(`/orders/${telegramId}/responses`);
        set({ orders: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при загрузке откликов пользователя:', error);
        showNotification('Ошибка при загрузке откликов', 'error');
        set({ loading: false });
      }
    },
    
    fetchResponsesByOrderId: async (orderId) => {
      set({ loading: true });
      try {
        const response = await api.get(`/orders/responses/${orderId}`);
        set({ responses: response.data, loading: false });
      } catch (error) {
        console.error('Ошибка при загрузке откликов по заказу:', error);
        showNotification('Ошибка при загрузке откликов', 'error');
        set({ loading: false });
      }
    },   
    
    assignOrder: async (orderId, executorId) => {
      set({ loading: true });
      try {
        const response = await api.post('/orders/assign', { orderId, executorId });
        set((state) => ({
          orders: state.orders.map((order) =>
            order._id === orderId ? response.data.order : order
          ),
          loading: false,
        }));
        showNotification('Исполнитель успешно назначен!', 'success');
        await get().fetchOrderDetails(orderId); 
      } catch (error) {
        console.error('Ошибка назначения исполнителя:', error.response?.data || error.message);
        showNotification('Ошибка при назначении исполнителя', 'error');
        set({ loading: false });
      }
    },
    
    proposeCancellation: async ({ orderId, userId }) => {
      try {
        const response = await api.post('/orders/cancel', { orderId, userId });
        set((state) => ({
          orders: state.orders.map((order) =>
            order._id === orderId ? { ...order, ...response.data.order } : order
          ),
        }));
        showNotification('Запрос на отмену обработан!', 'success');
      } catch (error) {
        console.error('Ошибка при обработке отмены:', error);
        showNotification(error.response?.data?.message || 'Ошибка при отмене', 'error');
      }
    },    
    
    completeOrder: async (orderId, fileLink) => {
      try {
        const response = await api.post('/orders/complete', { orderId, fileLink });
        set({ orders: state.orders.map(order => order._id === orderId ? response.data.order : order) });
      } catch (error) {
        console.error('Ошибка завершения заказа:', error);
      }
    },
    
    approveOrder: async (orderId) => {
      try {
        const response = await api.post('/orders/approve', { orderId });
        set({ orders: state.orders.map(order => order._id === orderId ? response.data.order : order) });
      } catch (error) {
        console.error('Ошибка подтверждения завершения заказа:', error);
      }
    },

    submitOrderForReview: async (orderId) => {
      try {
        const response = await api.post('/orders/submit-for-review', { orderId });
        set((state) => ({
          orders: state.orders.map((order) =>
            order._id === orderId ? { ...order, ...response.data.order } : order
          ),
        }));
        showNotification('Заказ сдан на проверку!', 'success');
      } catch (error) {
        console.error('Ошибка при сдаче заказа на проверку:', error);
        showNotification('Ошибка при сдаче заказа на проверку', 'error');
      }
    },
    
    returnOrderToWork: async (orderId) => {
      try {
        const response = await api.post('/orders/return-to-work', { orderId });
        set((state) => ({
          orders: state.orders.map((order) =>
            order._id === orderId ? { ...order, ...response.data.order } : order
          ),
        }));
        showNotification('Заказ возвращен на доработку!', 'info');
      } catch (error) {
        console.error('Ошибка при возврате заказа на доработку:', error);
        showNotification('Ошибка при возврате заказа на доработку', 'error');
      }
    },    
  }))
);

export default useOrderStore;