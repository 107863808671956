import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import { showNotification } from '../ToastNotification';
import WebApp from '@twa-dev/sdk';

const useWalletStore = create(
  devtools((set, get) => ({
    walletAddress: null,
    isLoading: false,
    error: null,
    successMessage: null,
    transactions: [],

    saveWalletAddress: async (telegramId, walletAddress) => {
        const id = telegramId || WebApp.initDataUnsafe?.user?.id;
      
        if (!id) {
          showNotification('Не удалось определить Telegram ID', 'error');
          return;
        }
      
        set({ isLoading: true, error: null });
        try {
          const response = await api.post(`/wallet/${id}/wallet`, { walletAddress });
          set({ walletAddress: response.data.walletAddress });
        } catch (error) {
          console.error('[saveWalletAddress] Error:', error.response || error.message);
          showNotification('Ошибка при сохранении адреса кошелька', 'error');
          set({ error: 'Ошибка при сохранении адреса кошелька' });
        } finally {
          set({ isLoading: false });
        }
    },

    verifyTonProof: async (walletAddress, proof) => {
      set({ isLoading: true, error: null });
      try {
        const response = await api.post(`/wallet/verify-ton-proof`, { walletAddress, proof });
        showNotification('TON Proof успешно подтвержден!', 'success');
      } catch (error) {
        showNotification('Ошибка при проверке TON Proof', 'error');
        set({ error: 'Ошибка при проверке TON Proof' });
      } finally {
        set({ isLoading: false });
      }
    },

    withdrawFunds: async (telegramId, amount) => {
      const id = telegramId || WebApp.initDataUnsafe?.user?.id;
      if (!id) {
        showNotification('Не удалось определить Telegram ID', 'error');
        return;
      }

      set({ isLoading: true, error: null });
      try {
        await api.post(`/wallet/${id}/withdraw`, { amount });
        showNotification('Средства успешно выведены!', 'success');
      } catch (error) {
        showNotification('Ошибка при выводе средств', 'error');
        set({ error: 'Ошибка при выводе средств' });
      } finally {
        set({ isLoading: false });
      }
    },

    disconnectWallet: async (telegramId) => {
      const id = telegramId || WebApp.initDataUnsafe?.user?.id;
      if (!id) {
          showNotification('Не удалось определить Telegram ID', 'error');
          return;
      }
  
      set({ isLoading: true, error: null });
      try {
          await api.delete(`/wallet/${id}/wallet`);
          set({ walletAddress: null });
          showNotification('Кошелек успешно отключен!', 'success');
      } catch (error) {
          console.error('[disconnectWallet] Error:', error.response || error.message);
          showNotification('Ошибка при отключении кошелька', 'error');
          set({ error: 'Ошибка при отключении кошелька' });
      } finally {
          set({ isLoading: false });
      }
    },

    updateBalance: async (telegramId, amount, currency) => {
      const id = telegramId || WebApp.initDataUnsafe?.user?.id;
      if (!id) {
        showNotification('Не удалось определить Telegram ID', 'error');
        return;
      }

      set({ isLoading: true, error: null });
      try {
        const response = await api.post(`/wallet/${id}/update-balance`, { amount, currency });

        if (currency === 'TON') {
          set((state) => ({ ...state, walletAddress: { ...state.walletAddress, balance: state.walletAddress.balance + amount } }));
        } else if (currency === 'USDT') {
          set((state) => ({ ...state, walletAddress: { ...state.walletAddress, balanceUSDT: state.walletAddress.balanceUSDT + amount } }));
        }

        showNotification('Баланс успешно обновлен!', 'success');
      } catch (error) {
        console.error('[updateBalance] Error:', error.response || error.message);
        showNotification('Ошибка при обновлении баланса', 'error');
        set({ error: 'Ошибка при обновлении баланса' });
      } finally {
        set({ isLoading: false });
      }
    },

    fetchTransactions: async (telegramId) => {
      if (!telegramId) {
        console.error('Не указан Telegram ID для загрузки транзакций');
        return;
      }

      set({ isLoading: true });
      try {
        const response = await api.get(`/wallet/${telegramId}/transactions`);
        set({ transactions: response.data.transactions });
      } catch (error) {
        console.error('Ошибка при загрузке транзакций:', error);
      } finally {
        set({ isLoading: false });
      }
    },

    withdrawFunds: async (telegramId, amount) => {
      const id = telegramId || WebApp.initDataUnsafe?.user?.id;
      if (!id) {
        showNotification('Не удалось определить Telegram ID', 'error');
        return;
      }
    
      set({ isLoading: true, error: null });
      try {
        const response = await api.post(`/wallet/${id}/withdraw-request`, { amount });
        showNotification('Заявка на вывод создана!', 'success');
      } catch (error) {
        console.error('[withdrawFunds] Ошибка:', error.response || error.message);
        showNotification('Ошибка при создании заявки на вывод', 'error');
        set({ error: 'Ошибка при создании заявки на вывод' });
      } finally {
        set({ isLoading: false });
      }
    }    
  }))
);

export default useWalletStore;
