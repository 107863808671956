import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useChatStore from '../store/chatStore';
import useUserStore from '../store/userStore';
import WebApp from '@twa-dev/sdk';
import DefaultAvatar from '../assets/defaultAvatar.png';
import { FaPaperPlane } from 'react-icons/fa';
import { BsPaperclip, BsXLg } from 'react-icons/bs';

function Chat() {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { currentChat, messages, sendMessage, sendFileMessage, fetchChatById, checkNewMessages } = useChatStore();
  const { otherUser, setOtherUser } = useUserStore();
  const [inputText, setInputText] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const currentUser = WebApp.initDataUnsafe?.user?.id;

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const backButton = WebApp.BackButton;

    backButton.show();
    backButton.onClick(() => {
      navigate('/chats');
    });

    return () => {
      backButton.hide();
      backButton.offClick();
    };
  }, [navigate]);

  useEffect(() => {
    if (!chatId) return;

    fetchChatById(chatId);

    const interval = setInterval(() => {
      checkNewMessages();
    }, 2000);

    return () => clearInterval(interval);
  }, [chatId, fetchChatById, checkNewMessages]);

  useEffect(() => {
    if (!currentChat?.participants || !currentUser) return;

    const opponent = currentChat.participants.find(
      (p) => String(p.telegramId) !== String(currentUser)
    );

    if (opponent) {
      setOtherUser({
        _id: opponent._id,
        nickname: opponent.nickname,
        avatar: opponent.avatar,
        telegramId: opponent.telegramId,
      });
    }
  }, [currentChat, currentUser, setOtherUser]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).filter((file) => file.size <= 10 * 1024 * 1024);
    if (files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
    } else {
      alert('Файл слишком большой. Максимальный размер — 10 МБ.');
    }
  };

  const handleSendMessage = () => {
    if (inputText.trim() || selectedFiles.length > 0) {
      if (selectedFiles.length > 0) {
        selectedFiles.forEach((file) => {
          sendFileMessage(chatId, inputText, file);
        });
        setSelectedFiles([]);
      } else {
        sendMessage(chatId, inputText);
      }
      setInputText('');
    }
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Предотвращает переход на новую строку
      handleSendMessage();
    }
  };

  return (
    <div className="Chat">
      <div className="headerChat">
        {otherUser ? (
          <a href={`/profile/${otherUser.telegramId}`}>
            <img
              src={otherUser.avatar ? `https://api.loonw-space.com:5000${otherUser.avatar}` : DefaultAvatar}
              alt="Avatar"
              className="avatar"
            />
            <span className="nickname">{otherUser.nickname || 'Собеседник'}</span>
          </a>
        ) : (
          <span>Загрузка...</span>
        )}
      </div>

      <div className="messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${String(msg.senderTelegramId) === String(currentUser) ? 'right' : 'left'}`}
          >
            {msg.text && <div className="message-text">{msg.text}</div>}
            {msg.file && (
              <div className="message-file">
                <a href={msg.file} target="_blank" rel="noopener noreferrer">
                  Скачать файл
                </a>
              </div>
            )}
            <div className="message-time">
              {new Date(msg.createdAt).toLocaleString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {selectedFiles.length > 0 && (
        <div className="selected-files">
          {selectedFiles.map((file, index) => (
            <div key={index} className="file-item">
              <span>{file.name}</span>
              <a onClick={() => handleRemoveFile(index)}>
                <BsXLg />
              </a>
            </div>
          ))}
        </div>
      )}

      <div className="addMessage">
        <label htmlFor="fileInput" className="customFileUploadButton">
          <BsPaperclip className="icon" />
        </label>
        <input type="file" onChange={handleFileChange} id="fileInput" className="fileInputHidden" multiple />
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Введите сообщение"
          onKeyPress={handleKeyPress} 
        />
        <button onClick={handleSendMessage}>
          <div className="icon">
            <FaPaperPlane />
          </div>
        </button>
      </div>
    </div>
  );
}

export default Chat;
