import React, { useEffect, useState } from 'react';

const convertDurationToMs = (duration) => {
  const durationMapping = {
    '1d': 24 * 60 * 60 * 1000,
    '2d': 2 * 24 * 60 * 60 * 1000,
    '3d': 3 * 24 * 60 * 60 * 1000,
    '1w': 7 * 24 * 60 * 60 * 1000,
    '2w': 14 * 24 * 60 * 60 * 1000,
  };

  return durationMapping[duration] || 0;
};

const Timer = ({ startDate, deadline }) => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (startDate && deadline) {
      const deadlineMs = new Date(startDate).getTime() + convertDurationToMs(deadline);
      const updateTimer = () => {
        const currentTime = Date.now();
        const remainingTime = deadlineMs - currentTime;
        setTimeLeft(remainingTime > 0 ? remainingTime : 0);
      };

      updateTimer();
      const interval = setInterval(updateTimer, 1000);

      return () => clearInterval(interval);
    }
  }, [startDate, deadline]);

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;

    return `${days > 0 ? `${days}д ` : ''}${hours}ч ${minutes}м ${seconds}с`;
  };

  return (
    <div className='timer'>
      {timeLeft > 0 ? (
        <>
        <div className="timerTitle">
          Осталось времени:
        </div>
        <div className='timerValue'>{formatTime(timeLeft)}</div>
        </>
      ) : (
        <div>Время истекло.</div>
      )}
    </div>
  );
};

export default Timer;
