import React from 'react';

function AcceptWindow({ isOpen, onClose, onApprove }) {
  if (!isOpen) return null;

  return (
    <div className="AcceptWindow">
      <div className="body">
        <div className="text">Вы уверены в действии?</div>

        <div className="buttons">
          <a href="#" className="close" onClick={(e) => {
            e.preventDefault();
            onClose();
          }}>
            Отмена
          </a>
          <a href="#" className="approve" onClick={(e) => {
            e.preventDefault();
            onApprove();
          }}>
            Подтвердить
          </a>
        </div>
      </div>
    </div>
  );
}

export default AcceptWindow;